.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

/*common*/
html {
    font-size: 62.5%;
}

body * {
    font-size: 1.6rem;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    font-family: "MS Pゴシック", sans-serif;;
}

body {
    margin: 0;
}

img {
    width: 100%;
    vertical-align: bottom;
}

input[type="text"] {
    border-radius: 100px;
    border: solid 1px #000000;
    text-align: center;
}

video {
    vertical-align: bottom;
}

.button {
    border-radius: 100px;
    text-align: center;
    border: none;
    font-weight: bold;
    color: #ffff;
    cursor: pointer;
    padding: 2px 0;
}

.status {
    display: inline-block;
    border-radius: 100px;
    padding: 3px 20px;
    color: #fff;
    font-weight: bold;
}

/*input-wrap*/
.input-wrap {
    position: relative;
    height: 100vh;
}

.input-content-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.input {
    width: 50%;
}

.setting {
    width: 70%;
}

.stage-participant-false {
    background-color: #707070;
}
.stage-participant-true {
    background-color: #1cc610;
}

.status p {
    font-size: 1.4rem;
}

.input-content {
    background-color: #f1f1f1;
    border-radius: 30px;
    text-align: center;
    padding: 20px;
    margin-top: 20px;
}

.input-content-name {
    margin-top: 50px;
    margin-bottom: 100px;
}

.input-content-setting {
    margin-top: 30px;
    margin-bottom: 30px;
}

.authority {
    text-align: right;
    background-color: #707070;
}

.input-text {
    font-size: 2.5rem;
    font-weight: bold;
}

.input-sub-text {
    margin-top: 20px;
}

.input-inline {
    margin-top: 40px;
}

.input-inline-before {
    margin-top: 10px;
}

.input-inline input {
    padding: 10px;
    width: 50%;
    min-width: 330px;
}

.input-inline input::placeholder {
    text-align: center;
    font-weight: bold;
}

.inline-button {
    width: 50%;
    min-width: 330px;
    font-size: 1.7rem;
}

.blue-button {
    background-color: #0070C0;
    /*font-size: 1.7rem;*/
    padding: 10px;
}

.red-button {
    background-color: #e03a2c;
}

.green-button {
    background-color: #00B050;
}
.orange-button {
    background-color: #e26419;
}
.gray-button {
    background-color: #a4a4a4;
}
.streams-setting-button {
    width: 20%;
    min-width: 140px;
    padding: 10px 0;
    margin-right: 10px;
}


.application {
    margin-left: 10px;
}

.user-info-button {
    min-width: 70px;
    font-size: 1.4rem;
    padding: 3px 0;
}

.text-right {
    text-align: right;
}


/* ここから */
.setting-info-wrap {
    background-color: #ffff;
    border: solid 2px #c5c5c5;
    width: 80%;
    min-width: 650px;
    margin: auto;
    text-align: left;
}

.setting-info-inner {
    width: 80%;
    margin: auto;
    padding: 40px 0;
}

.setting-info-title {
    font-size: 1.8rem;
}

.setting-info-container {
    display: flex;
    align-items: center;
}

.setting-info-item-title {
    margin-top: 20px;
}

.setting-info-item-title h2 {
    font-size: 1.8rem;
}

.setting-info-icon {
    width: 13px;
    display: flex;
    margin-left: 15px;
    cursor: pointer;
}

.setting-info-item-content {
    font-size: 1.1rem;
    word-break: break-all;
    margin-top: 10px;
}

.setting-info-inline {
    text-align: center;
    margin-top: 30px;
}

.blue-short-button {
    padding: 5px 50px;
    background-color: #0070C0;
}

/*配信画面*/
.distribution-wrap {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin: auto;
    margin-top: 20px;
}

.video-wrap {
    width: 70%;
}

.streams-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.stream-settings {
    width: 100%;
}

.stream-settings-container {
    display: flex;
    justify-content: end;
}
.media-setting {
    display: flex;
}

/*アイコン*/
.setting-icon {
    border-radius: 100px;
    background-color: #ececec;
    width: 45px;
    height: 45px;
    position: relative;
    margin-right: 10px;
}

.icon-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*リクエスト*/
.request-wrap {
    position: fixed;
    bottom: 0;
    right: 20px;
    background-color: #333333;
    padding: 10px 10px;
    width: 20%;
    z-index: 2;
}

.request-wrap button:last-of-type {
    margin-left: 10px;
}

.request-user-name {
    font-weight: bold;
    color: #ffff;
}

/*チャット*/
/* 大枠 */
.chat-flex-item {
    width: 25%;
}

.chat-title-text {
    font-size: 1.8rem;
    font-weight: bold;
    border-bottom: solid 1px #d7d7d7;
    padding: 5px 0;
}

/* 中身 */
.chat-content {
    margin-top: 10px;
    height: 500px;
    overflow: auto;
    border: solid 1px #d8d8d8;
    border-bottom: none;
    background-color: #f6f6f6;
    border-radius: 10px 10px 0 0;
    padding: 5px;
}

.chat-inner {
    padding: 5px;
}

/* メッセージ部分 */
.chat-item {
    margin-top: 20px;
}

.chat-item:first-of-type {
    margin-top: 0;
}

.chat-user-info {
    display: flex;
    align-items: center;
}

.chat-user-name {
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.3rem;
}

.chat-icon {
    width: 25px;
    height: 25px;
    border-radius: 100px;
}

.damy-icon {
    background-color: black;
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

.chat-text {
    margin-top: 10px;
    background-color: #0070c036;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
    font-size: 1.4rem;
    word-break: break-all;

}

.chat-time {
    font-size: 1.1rem;
    color: #8f8f8f;
}

/* 送信部分 */
.chat-send-wrap {
    padding: 10px 0;
    border: solid 1px #d8d8d8;
    border-radius: 0 0 10px 10px;
}

.chat-send-inner {
    width: 90%;
    margin: auto;
}

.chat-send-container {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.chat-input-area {
    width: 100%;
}

.chat-input-area textarea {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 100px;
    background-color: #eaeaea;
    text-align: left;
    font-size: 1.3rem;
    resize: none;
    height: 40px;
}
.chat-input-area input::placeholder {
    font-size: 1.3rem;
}

.chat-send-icon {
    width: 35px;
    margin-left: 20px;
}

/*設定*/
.setting-detail-wrap {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px 0;
    border: solid 1px #bbb;
    z-index: 2;
    min-width: 300px;
}

.setting-detail-inner {
    width: 90%;
    margin: auto;
}

.setting-detail-title {
    font-size: 2rem;
    font-weight: bold;
}

.setting-webcam-wrap {
    margin-top: 20px;
}

.setting-mic-wrap {
    margin-top: 20px;
}

.setting-detail-wrap label {
    font-size: 1.4rem;
}

.setting-detail-wrap select {
    border: none;
    padding: 10px;
    background-color: #e9e9e9;
    border-radius: 5px;
    width: 100%;
    display: inline-block;

}

/*ビデオ*/
/*.participantContainer {*/
/*  min-width: 300px;*/
/*}*/

.user-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.user-name-container {
    display: flex;
    align-items: center;
}

.muted-screen-wrap {
    position: relative;
}
.muted-screen {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #2a2b34;
    border-radius: 20px;
}
.muted-screen-icon {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

 .top-screen {
     /*max-height: 400px;*/
     object-fit: contain;
     /*width: auto;*/
     width: 100%;
     border: solid 1px #e7e7e7;
 }
 .low-screen {
     max-height: 120px;
     object-fit: contain;
     width: auto;
     border: solid 1px #e7e7e7;
 }

 /*.topScreenContainer {*/
 /*    display: inline-block;*/
 /*}*/

.lowScreenContainer {
    display: inline-block;
}


/*モーダル*/
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.modal-bg {
    width: 100%;
    height: 100%;
    background-color: #00000040;
}
.modal-content-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 650px;
}

.modal-link {
    font-size: 1.8rem;
    color: blue;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1250px){
    .top-screen {
        width: 100%;
    }
}


/*1000px以下*/
@media screen and (max-width: 1000px){
    .distribution-wrap {
        display: block;
    }
    .video-wrap {
        width: 100%;
    }
    .chat-flex-item {
        width: 100%;
        margin-top: 10px;
    }
}

/*900以下*/
@media screen and (max-width: 900px){
    .input {
        width: 95%;
    }
}

/*700以下*/
@media screen and (max-width: 700px){
    .modal {
        overflow: auto;
    }
    .modal-content-wrap {
        min-width: unset;
    }
    .setting-info-wrap {
        min-width: unset;
    }
    .setting {
        width: 100%;
        height: 100%;
    }
    .setting-info-wrap {
        width: 100%;
    }
}

/*550以下*/
@media screen and (max-width: 592px){
    .streams-wrap {
        display: block;
    }
    .stream-settings-container {
        justify-content: space-between;
        margin-top: 10px;
        display: block;
    }
    .media-setting {
        display: flex;
    }

    .treams-setting{ 
        margin-top: 10px;
        margin-left: 5px;
    }
    .streams-setting-button{ 
        margin-right: 5px;
    }
    .blue-button {
        font-size: 1.4rem;
    }

    .input-inline input {
        width: 90%;
        min-width: 300px;
    }
    .inline-button {
        width: 90%;
        min-width: 300px;
    }
}